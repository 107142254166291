import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Link from "../utils/Link"
import Image from "../utils/Image"

import Box from "../atoms/Box"
import Button from "../atoms/Button"
import Card from "../atoms/Card"
import Flex from "../atoms/Flex"

import SectionTitle from "../molecules/SectionTitle"

const StyledCard = styled(Card)`
  &:hover {
    .card-back-reveal {
      opacity: 1;
    }
  }
`

const StyledLink = styled(Link)`
  &:hover {
    .card-front-logo {
      ${props => (props.zoomOnHover ? "transform:scale(1.05)" : null)}
    }
  }
`

const LogoBox = ({
  image,
  cardFront,
  overlayImage,
  shouldShowOverlay,
  imgMaxHeight,
  imageStyles,
  ...rest
}) => {
  const border = {}
  if (!image) {
    //show empty box if there is not logo
    border.borderColor = "border.0"
    border.borderWidth = "0.5px"
    border.borderStyle = "solid"
  }
  return (
    <Flex
      className="card-front-logo"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      {...rest}
    >
      <Flex
        alignItems="center"
        position="relative"
        transitionProperty="transform"
        transitionTimingFunction="ease"
        transitionDuration="md"
        minHeight={4}
        width="100%"
        height="100%"
        padding={imageStyles?.padding}
        {...border}
      >
        {image && (
          <Image
            alternativeText="Logo"
            {...image}
            style={{ height: "100%" }}
            imgStyle={{
              objectFit: imageStyles?.objectFit,
              height: "100%",
              width: "100%",
            }}
          />
        )}
      </Flex>
    </Flex>
  )
}

const RevealBox = ({
  align,
  cardBack,
  button,
  sectionTitle,
  verticalAlign,
  shouldShowInfo,
  shouldShowOverlay,
  overlayImage,
  overlayImageStyles,
  ...rest
}) => {
  return (
    <Card {...cardBack} {...rest}>
      <Box
        backgroundColor="inherit"
        width="100%"
        height="100%"
        top="0"
        position="absolute"
      >
        {shouldShowInfo && (
          <Flex
            flexDirection="column"
            justifyContent={
              verticalAlign === "center" ? "center" : "space-between"
            }
            alignItems={align === "center" ? "center" : "flex-start"} //alignment for button
            className="card-reveal"
            position="absolute"
            top="0"
            width="100%"
            height="100%"
            p={5}
            style={{ zIndex: 100 }}
          >
            <Box maxHeight="calc(100% - 48px)" overflow="hidden">
              {sectionTitle && <SectionTitle {...sectionTitle} align={align} />}
            </Box>
            {button && (
              <Button
                aria-label="CTA Button in reveal text box for the logo card"
                {...button}
              />
            )}
          </Flex>
        )}
        {shouldShowOverlay && (
          <Flex
            style={{
              height: "100%",
              left: 0,
              right: 0,
              bottom: 0,
              position: "absolute",
            }}
            padding={overlayImageStyles?.padding}
          >
            <Image
              className="overlay-image"
              {...overlayImage}
              style={{ height: "100%" }}
              imgStyle={{
                objectFit: overlayImageStyles?.objectFit,
                height: "100%",
                width: "100%",
                opacity: overlayImageStyles?.opacity,
              }}
            />
          </Flex>
        )}
      </Box>
    </Card>
  )
}

const LogoItem = ({
  image,
  overlayImage,
  hoverBehavior,
  cardFront,
  link,
  sectionTitle,
  LSectionTitle,
  button,
  cardBack,
  align,
  verticalAlign,
  zoomOnHover,
  shouldShowInfo = true,
  cardMaxHeight,
  customCardMaxHeight,
  imageStyles,
  overlayImageStyles,
  tags,
  ...rest
}) => {
  const shouldShowOverlay =
    overlayImage && (hoverBehavior === "image" || hoverBehavior === "all")
  sectionTitle = LSectionTitle || sectionTitle
  shouldShowInfo =
    shouldShowInfo && cardBack.useCard && (sectionTitle || button) // ||richText?.content

  return (
    <StyledLink
      aria-label="Logo link"
      className="logo-card-link"
      zoomOnHover={hoverBehavior !== "info" ? zoomOnHover : false}
      width="100%"
      {...link}
      optional
    >
      <StyledCard
        {...cardFront}
        {...rest}
        ratio={
          cardMaxHeight === "fullHeight"
            ? 1
            : cardMaxHeight === "default"
            ? 0.7
            : 1
        }
        maxHeight={
          cardMaxHeight === "custom" && customCardMaxHeight > 0
            ? `${customCardMaxHeight}px`
            : 40
        }
      >
        <LogoBox
          // p={{ _: 4, md: 5, }}
          imageStyles={imageStyles}
          image={image}
          cardFront={cardFront}
          overlayImage={overlayImage}
          shouldShowOverlay={shouldShowOverlay}
        />
        {(shouldShowInfo || shouldShowOverlay) && (
          <RevealBox
            className="card-back-reveal"
            shouldShowInfo={shouldShowInfo}
            shouldShowOverlay={shouldShowOverlay}
            overlayImageStyles={overlayImageStyles}
            overlayImage={overlayImage}
            cardBack={cardBack}
            sectionTitle={sectionTitle}
            align={align}
            verticalAlign={verticalAlign}
            button={button}
            transitionDuration="fast"
            transitionTimingFunction="ease-in-out"
            transitionProperty="opacity"
            opacity={0}
          />
        )}
      </StyledCard>
    </StyledLink>
  )
}

export default LogoItem

export const query = graphql`
  fragment Logo on STRAPI__COMPONENT_MOLECULES_LOGO {
    image {
      ...ImageWith300pxWidth
    }
    overlayImage {
      ...ImageWith300pxWidth
    }
    LSectionTitle: sectionTitle {
      ...SectionTitle
    }
    button {
      ...Button
    }
    link {
      ...Link
    }
    tags {
      name
    }
    color
  }
`
